<template>
	<div class="visit">
		<div class="visitbox">
			<div class="logo">
				<img :src="require('../../assets/logo.jpeg')" alt="" />
			</div>

			<div class="coenter" v-if="!is_showQCode" style="margin-bottom: 1.2rem">
				<div class="ctitle bordercolor">
					<div class="tipred">* จำเป็นต้องกรอก</div>
				</div>
			</div>
			<van-form ref="MYSform" v-if="!is_showQCode">
				<div v-for="(item, index) in formdata" :key="index">
					<div :id="item.key"></div>
					<div v-if="item.valueother !== undefined && !item.hidden">
						<van-field v-if="item.title && item.trigger" :name="item.key" :rules="[
                {
                  validator: validatorther,
                  message: '* จำเป็นต้อง',
                  name: item.key,
                },
              ]">
							<template #label> {{ item.num }} 、{{ item.title }}<span class="colorred">*</span>
							</template>
							<template #input>
								<div v-if="!item.multiple" style="width: 100%">
									<div v-if="item.type == 'Radio'">
										<van-radio-group v-model="item.value"
											@change="changeradio({ value: item.value, key: item.key })">
											<van-radio checked-color="#5263a2" v-for="(citem, cindex) in item.list"
												:key="cindex" :name="citem">{{ citem }} </van-radio>
										</van-radio-group>
										<input v-if="item.otherinput" style="width: 80%" @blur="setvalueother(item.key)"
											:disabled="item.valueotherstrdisabled" v-model="item.valueother"
											placeholder="อื่นๆ" />
									</div>
								</div>
								<div v-if="item.multiple" style="width: 100%">
									<template v-if="item.score">
										<div v-for="(citem, cindex) in item.list">
											{{ citem.key }}<br />
											<van-rate v-model="citem.val" :count="10" color="#92a4d7"
												@change="score_error = false" />
										</div>
										<div class="van-field__error-message" v-if="score_error">* จำเป็นต้อง</div>
									</template>
									<template v-else>
										<van-checkbox-group v-model="item.value"
											@change="changecheckbox({ value: item.value, key: item.key, item })">
											<van-checkbox v-if="item.showDisabled" checked-color="#5263a2" key="15"
												:name="item.showDisabled"
												:disabled="item.value.length ? !item.disabledItem : false"
												class="mb10">{{ item.showDisabled }} </van-checkbox>
											<van-checkbox :disabled="item.value.length ? item.disabledItem : false"
												checked-color="#5263a2" v-for="(citem, cindex) in item.list"
												:key="cindex" :name="citem" class="mb10">{{ citem }} </van-checkbox>
										</van-checkbox-group>
										<input v-if="item.otherinput" style="width: 80%" @blur="setvalueother(item.key)"
											:disabled="item.valueotherstrdisabled" v-model="item.valueother"
											placeholder="อื่นๆ" />
									</template>
								</div>
							</template>
						</van-field>
					</div>
					<div v-if="item.valueother === undefined && !item.hidden">
						<!-- <div :id="item.key"></div> -->
						<van-field v-if="item.title && item.trigger" :name="item.key"
							:rules="[{ required: true, message: '* จำเป็นต้อง' }]">
							<template #label> {{ item.num }} 、{{ item.title }}<span class="colorred">*</span>
							</template>
							<template #input>
								<div v-if="!item.multiple" style="width: 100%">
									<van-radio-group v-model="item.value" v-if="item.type == 'Radio'">
										<van-radio checked-color="#5263a2" v-for="(citem, cindex) in item.list"
											:key="cindex" :name="citem">{{ citem }} </van-radio>
									</van-radio-group>
									<van-radio-group v-model="item.value" v-if="item.type == 'input'">
										<input type="hidden" v-model="item.value" />
										<div v-for="(citem, cindex) in item.list" :key="cindex">
											<div>
												<span> {{ cindex + 1 }}、{{ citem.title }}</span>
											</div>
											<input style="width: 80%" @blur="setvalue(item.key)" v-model="citem.value"
												maxlength="40" :placeholder="citem.title" />
										</div>
									</van-radio-group>
								</div>
								<div v-if="item.multiple" style="width: 100%">
									<van-checkbox-group v-model="item.value">
										<van-checkbox checked-color="#5263a2" v-for="(citem, cindex) in item.list"
											:key="cindex" :name="citem" class="mb10">{{ citem }} </van-checkbox>
									</van-checkbox-group>
								</div>
							</template>
						</van-field>
					</div>
					<div v-if="!item.title && !item.hidden">
						<van-field :rules="[{ validator, message: '* จำเป็นต้อง' }]">
							<template #input>
								<van-radio-group v-model="item.value">
									<div>
										<div v-for="(citem, cindex) in item.list" :key="cindex">
											<div>
												<span> {{ citem.title }}</span>
											</div>
											<input style="width: 80%" @blur="setvalue(item.key, '1')"
												v-model="citem.value" :placeholder="citem.title"
												:maxlength="citem.maxlen" :type="citem.intputype" :name="citem.key" />
											<div>
												<span class="colorred">{{ citem.describe }}</span>
											</div>
										</div>

										<div class="describe"><span class="colorred"> สังเกต:</span>{{ item.describe }}
										</div>
									</div>
								</van-radio-group>
							</template>
						</van-field>
					</div>
				</div>

				<div class="btnbox">
					<van-button round block class="btns btnssubmit" type="default" @click="onSubmit">ส่ง</van-button>
				</div>
			</van-form>
			<div class="myc" v-else>
				<!-- <div style="text-align: center">ขอบคุณสำหรับความคิดเห็นของคุณ,</div>
        <div style="width: 90%; text-align: center; margin: 0 auto">
          <span class="colorred"> เราจะส่งรหัสส่วนลด Starbucks 100 บาท </span>
          <div><span class="colorred" style="font-weight: bold; font-size: 16px">+</span></div>
          <div><span class="colorred">รหัสส่วนลดของ Simplus Store ให้คุณ</span></div>
        </div> -->
				<!-- <div style="text-align: center">
            <img style="width: 60%" :src="require('../../assets/Q.png')" alt="" />
          </div> -->
				<div style="width: 90%; text-align: center; margin: 0 auto">แบบสำรวจนี้สิ้นสุดแล้ว
					โปรดติดตามเราสำหรับข้อมูลเพิ่มเติม!</div>
				<div style="text-align: center; margin: 0.4rem">
					<a href="https://lin.ee/c8S40rY"><img src="https://scdn.line-apps.com/n/line_add_friends/btn/th.png"
							alt="เพิ่มเพื่อน" height="50" border="0" /></a>
				</div>

				<div style="text-align: center">Simplus LINE (Line OA: @simplusth)</div>
				<div style="display: flex; justify-content: space-around; text-align: center; margin-top: 1rem">
					<p>
						<a
							href="https://shopee.co.th/simplus_officialshop?utm_campaign=s448235616_ss_th_othr_crmh5&utm_content=crm&utm_medium=seller&utm_source=brandcrm">
							<img style="width: 40%" :src="require('../../assets/shopee.png')" alt="Shopee Simplus" />
						</a>
					</p>
					<p>
						<a href="https://c.lazada.co.th/t/c.bg27oH?intent=false&fallback=true&url=https%3A%2F%2Fwww.lazada.co.th%2Fshop%2Fsimplus-official-store"
							title=" Lazada Simplus">
							<img style="width: 40%" :src="require('../../assets/lazada.png')" alt="TikTok Simplus" />
						</a>
					</p>
					<p>
						<a href="https://www.tiktok.com/@simplusappliance" title="TikTok Simplus">
							<img style="width: 40%" :src="require('../../assets/tt.png')" alt="TikTok Simplus" />
						</a>
					</p>
				</div>
			</div>
		</div>

		<!--  审核 -->
		<van-popup v-model.sync="showMsg" :close-on-click-overlay="false"
			:overlay-style="{ background: 'rgba(0,0,0,0.4)' }" :round="true">
			<div class="msg-box">
				<div class="msg-close" @click="$router.back()">
					<van-icon name="close" />
				</div>
				<div class="msg-logo">
					<img class="logo" src="../../assets/images/success.png" />
				</div>
				<!-- 提交成功提示 -->
				<div class="msg-title">ขอบคุณสำหรับการเข้าร่วม</div>
				<!-- Lazada -->
				<div class="msg-content">Lazada:<a class="link-text" href="https://bit.ly/3MrGtXG">คลิกเพื่อรับคูปอง</a>
				</div>
				<!-- Shopee -->
				<div class="msg-content">Shopee:<span class="link-text"
						@click="handCopy('SIMPNPS50')">SIMPNPS50</span><b>⬅(คลิกเพื่อคัดลอก)</b></div>
			</div>
		</van-popup>

		<!-- <van-button class="btns btnssubmit" type="default" @click="clearData">清除记录</van-button> -->
	</div>
</template>

<script>
	import {
		Form,
		Field,
		Checkbox,
		CheckboxGroup,
		Icon,
		Button,
		Uploader,
		CellGroup,
		Toast,
		RadioGroup,
		Radio,
		Popup,
		Rate
	} from "vant";

	export default {
		components: {
			[Form.name]: Form,
			[Field.name]: Field,
			[Uploader.name]: Uploader,
			[Radio.name]: Radio,
			[RadioGroup.name]: RadioGroup,
			[Button.name]: Button,
			[Checkbox.name]: Checkbox,
			[Icon.name]: Icon,
			[CheckboxGroup.name]: CheckboxGroup,
			[CellGroup.name]: CellGroup,
			[Popup.name]: Popup,
			[Rate.name]: Rate,
		},
		watch: {
			formdata: {
				handler(val) {
					let str = "",
						str1 = "";
					for (let i = 0; i < val.length; i++) {
						if (val[i].key === "think_brand") {
							this.$nextTick(() => {
								if (val[i].value.indexOf("Simplus") != -1) {
									this.settrigger(0);
								} else {
									this.settrigger(1);
								}
							});
						}

						if (val[i].key === "is_simplus") {
							str = val[i].value;
						}

						if (val[i].key === "is_simplus_whether") {
							val[i].hidden = str && str.includes("ใช่") ? false : true;
							if (!str || !str.includes("ใช่")) {
								val[i].value = '';
							}
						}

						if (val[i].key === "is_use_service") {
							str1 = val[i].value;
						}

						if (val[i].key === "score") {
							val[i].hidden = str1 && str1.includes("ใช่") ? false : true;
							if (!str1 || !str1.includes("ใช่")) {
								val[i].value = '';
							}
						}
					}
					this.setvaluenum();
				},
				deep: true,
			},
		},
		data() {
			return {
				isSubmit: false,
				score_error: false,
				formdata: [{
						num: 1, //1
						title: "คุณเคยสั่งซื้อสินค้าของ Simplus กี่ครั้ง",
						type: "Radio",
						multiple: false,
						trigger: true,
						value: null,
						ver: true,
						otherinput: false,
						list: ["1", "2", "3", "≥4"],
						key: "buy_nums",
					},
					{
						num: 2, //2
						title: "คุณประทับใจอะไรในแบรนด์ Simplus (หลายตัวเลือก)",
						type: "Radio",
						ver: true,
						multiple: true,
						trigger: true,
						otherinput: false,
						valueother: null,
						valueotherstr: null,
						valueotherstrdisabled: false,
						value: [],
						showDisabled: "ไม่มีความประทับใจเป็นพิเศษ", //是否展示禁用某个选项
						disabledItem: true, //禁用
						list: ["เป็นที่นิยม", "ใช้งานได้อย่างมีประโยชน", "สไตล์วัยรุ่น", "มีความเป็นเอกลักษณ์",
							"มีสไตล์", "ใส่ใจคุณภาพสินค้า", "คุณภาพเกินราคา", "ทั่วไป"
						],
						key: "impression",
					},
					{
						num: 3, //原1
						title: "เต็ม 10 คะแนน, คุณอยากจะแนะนำSimplusให้กับเพื่อนหรือครอบครัวของคุณกี่คะแนน?",
						type: "Radio",
						multiple: false,
						ver: true,
						trigger: true,
						otherinput: false,
						list: ["10 คะแนน", "9 คะแนน", "8 คะแนน", "7 คะแนน", "6 คะแนน", "5 คะแนน", "4 คะแนน", "3 คะแนน",
							"2 คะแนน", "1 คะแนน"
						],
						value: null,
						key: "irrigation_ditch",
					},
					{
						num: 4, //原6
						title: "คุณสั่งซื้อเครื่องใช้ไฟฟ้าจากช่องทางใด (หลายตัวเลือก)",
						type: "Radio",
						ver: true,
						multiple: true,
						trigger: true,
						otherinput: true,
						valueother: null,
						valueotherstr: null,
						valueotherstrdisabled: true,
						value: [],
						list: ["Shopee", "Lazada ", "Tiktok shop", "Thisshop", "ช่องทางออฟไลน์", "อื่นๆ"],
						key: "other_platform",
					},
					{
						num: 5, //原7
						title: "คุณเคยสั่งซื้อเครื่องใช้ไฟฟ้ายี่ห้อใดต่อไปนี้ (หลายตัวเลือก)",
						type: "input",
						ver: true,
						trigger: true,
						multiple: true,
						value: [],
						valueother: null,
						valueotherstr: null,
						otherinput: true,
						valueotherstrdisabled: true,
						showDisabled: "ยังไม่ได้ซื้อยี่ห้ออื่น", //是否展示禁用某个选项
						disabledItem: true, //禁用
						list: ["PHILIPS", "Gaabor", "Dyson", "Xiaomi", "Tefal", "Hatari", "Otto", "Smarthome",
							"Deerma", "KASHIWA"
						],
						key: "think_brand",
					},
					{
						num: 6, //新增
						title: "เต็ม 10 คะแนน, คุณอยากจะแนะนำแบรนด์เหล่านี้ให้กับเพื่อนหรือครอบครัวของคุณกี่คะแนน?",
						type: "Radio",
						ver: true,
						trigger: true,
						score: true,
						multiple: true,
						value: [],
						valueother: null,
						valueotherstr: null,
						otherinput: false,
						valueotherstrdisabled: false,
						hidden: true,
						list: [],
						key: "brand_score",
					},
					{
						num: 7, //原8
						title: "สิ่งที่คุณสนใจมากที่สุดเมื่อซื้อเครื่องใช้ไฟฟ้าในบ้าน (ตัวเลือกเดียว) ",
						type: "Radio",
						ver: true,
						multiple: false,
						value: null,
						trigger: true,
						//indexNumber: true,
						valueother: null,
						valueotherstr: null,
						otherinput: true,
						valueotherstrdisabled: true,
						list: ["คุณภาพ", "ดีไซน์,รูปทรงด้านนอก", "ราคา", "ชื่อเสียงของแบรนด์/แบรนด์แอมบาสเดอร์",
							"ก่อนซื้อ & บริการหลังการขาย", "ขนส่ง", "อื่นๆ"
						],
						key: "what_about",
					},
					{
						num: 8, //原9
						title: "คุณรู้จักแบรนด์ Simplus จากแพลตฟอร์มใด?(เลือกได้หลายข้อ)",
						type: "Radio",
						ver: true,
						trigger: true,
						multiple: true,
						value: [],
						otherinput: true,
						valueother: null,
						valueotherstr: null,
						valueotherstrdisabled: true,
						list: ["ผู้คนรอบข้าง เพื่อน/ครอบครัว", "โฆษณาในลิฟต์", "เน็ตไอดอล (คนดังทางอินเตอร์เน็ต)",
							"Lazada/Shopee", "Tiktok", "Facebook", "Instagram", "twitter ", "Line"
						],
						key: "buy_brand",
					},
					{
						num: 9, //新增
						title: "คุณรู้หรือไม่ว่า แบรนด์แอมบาสเดอร์ของแบรนด์ Simplus คือใคร (เลือกได้หลายข้อ)",
						type: "Radio",
						ver: true,
						multiple: true,
						trigger: true,
						otherinput: false,
						valueother: null,
						valueotherstr: null,
						valueotherstrdisabled: false,
						value: [],
						showDisabled: "ไม่ทราบ", //是否展示禁用某个选项
						disabledItem: true, //禁用
						list: ["Win", "Bright", "BamBam", "PP Krit"],
						key: "spokesman",
					},
					{
						num: 10, //原10
						title: "คุณเคยเห็น KOLโพสต์เกี่ยวกับสินค้า Simplus บนแพลตฟอร์ม Tiktok,Instagram หรือไม่？ ",
						type: "Radio",
						ver: true,
						trigger: true,
						multiple: false,
						value: null,
						indexNumber: true,
						list: ["เคย", "ไม่เคย"],
						key: "first_impression",
					},
					{
						num: 11, //原11
						title: "เพราะอะไรคุณถึงชอบสินค้าของ Simplus? (เลือกได้หลายข้อ)",
						type: "Radio",
						ver: true,
						trigger: true,
						multiple: true,
						value: [],
						otherinput: true,
						//indexNumber: true,
						valueother: null,
						valueotherstr: null,
						valueotherstrdisabled: true,
						list: ["ตัวสินค้า (คุณภาพดี,รูปทรงดี)", "คุ้มค่าสมราคา",
							"ภาพลักษณ์ของแบรนด์ดี (เนื้อหาสื่อเป็นทางการดี)", "Bright พรีเซนเตอร์​แบรนด์​ Simplus",
							"คนดังทางโซเชียล KOL", "รีวิวจากลูกค้า",
							"รับประกัน 1 ปี  เปลี่ยนใหม่ ไม่รอซ่อม บริการหลังการขาย"
						],
						key: "where_simplus",
					},
					{
						num: 12, //原15
						title: "คุณคิดว่า Simplus มีส่วนไหนที่ต้องปรับปรุงเพิ่มเติมบ้าง?(เลือกได้หลายข้อ) ",
						type: "Radio",
						ver: true,
						multiple: true,
						key: "simplus_KOL",
						value: [],
						trigger: true,
						valueother: null,
						valueotherstr: null,
						otherinput: true,
						valueotherstrdisabled: true,
						showDisabled: "ไม่พบปัญหาที่ต้องแก้ไข", //是否展示禁用某个选项
						disabledItem: true, //禁用
						list: ["คุณภาพสินค้า", "ดีไซน์ของผลิตภัณฑ์", "โฆษณาบ่อยเกินไป",
							"การบริการลูกค้าของแอดมิน,ความเร็วในการตอบแชท", " ความเร็วของขนส่ง"
						],
					},
					{
						num: 13, //原12
						title: 'คุณเคยได้ยินเกี่ยวกับบริการหลังการขายของ Simplus ว่า "รับประกัน 1 ปี เปลี่ยนใหม่ ไม่รอซ่อม" หรือไม่ ?',
						type: "Radio",
						ver: true,
						multiple: false,
						value: null,
						trigger: true,
						key: "is_simplus",
						otherinput: true,
						// indexNumber: true,
						//valueother: null,
						//valueotherstr: null,
						//valueotherstrdisabled: true,
						list: ["ใช่", "ไม่เคย"], //, "ไม่ทราบว่ามีบริการหลังการขายนี้"
					},
					{
						num: 14, //原13
						title: "คุณเคยใช้บริการหลังการขาย รับประกัน 1 ปี เปลี่ยนใหม่ ไม่รอซ่อม",
						type: "Radio",
						ver: true,
						multiple: false,
						value: "",
						trigger: true,
						key: "is_simplus_whether",
						otherinput: true,
						// indexNumber: true,
						//valueother: null,
						//valueotherstr: null,
						//valueotherstrdisabled: true,
						list: ["ใช่", "ไม่เคย"], //, "ไม่ทราบว่ามีบริการหลังการขายนี้"
						hidden: true,
					},
					{
						num: 15, //新增
						title: "คุณเคยใช้บริการหลังการขายของsimplusหรือไม่?",
						type: "Radio",
						ver: true,
						multiple: false,
						value: "",
						trigger: true,
						otherinput: true,
						// indexNumber: true,
						//valueother: null,
						//valueotherstr: null,
						//valueotherstrdisabled: true,
						list: ["ใช่", "ไม่เคย"], //, "ไม่ทราบว่ามีบริการหลังการขายนี้"
						key: "is_use_service", //是否体验过客服服务
					},
					{
						num: 16, //原14
						title: "เต็ม 10คะแนน , โปรดให้คะแนนการบริการลูกค้าของ Simplus",
						type: "Radio",
						ver: true,
						trigger: true,
						indexNumber: true,
						multiple: false,
						value: null,
						otherinput: true,
						list: ["10 คะแนน", "9 คะแนน", "8 คะแนน", "7 คะแนน", "6 คะแนน", "5 คะแนน", "4 คะแนน", "3 คะแนน",
							"2 คะแนน", "1 คะแนน"
						],
						key: "score",
						hidden: true,
					},
					{
						num: 17, //原2
						title: "อายุ",
						type: "Radio",
						multiple: false,
						trigger: true,
						value: null,
						ver: true,
						otherinput: false,
						list: ["<20", "20-30", "31-40", "41-50", ">50"],
						key: "age",
					},
					{
						num: 18, //原3
						title: "รายได้ต่อเดือน",
						type: "Radio",
						ver: true,
						trigger: true,
						multiple: false,
						otherinput: false,
						value: null,
						list: ["<10,000THB", "10,000-29,999THB", "30,000-49,999THB", "50,000-99,999THB",
							">100,000THB"
						],
						key: "monthly_income",
					},
					{
						num: 19, //原4
						title: "อาชีพ",
						type: "Radio",
						ver: true,
						trigger: true,
						multiple: false,
						value: null,
						valueother: null,
						valueotherstrdisabled: true,
						valueotherstr: null,
						otherinput: false,
						list: [
							"ผู้เชี่ยวชาญ (เช่น ครู/แพทย์/ทนายความ เป็นต้น)",
							"บุคลากรในอุตสาหกรรมบริการ (พนักงานเสิร์ฟอาหาร/คนขับรถ/พนักงานขาย ฯลฯ)",
							"ฟรีแลนซ์ (เช่น นักเขียน/ศิลปิน/ช่างภาพ/มัคคุเทศก์ ฯลฯ)",
							"คนงาน (เช่น คนงานในโรงงาน/คนงานก่อสร้าง/คนงานสุขาภิบาลในเมือง เป็นต้น)",
							"พนักงานบริษัท",
							"สถาบันของรัฐ/ข้าราชการ/พนักงานของรัฐ",
							"นักศึกษา",
							"แม่บ้าน",
							"อื่นๆ(เช่น ว่างงาน/เกษียณอายุ)",
						],
						key: "profession",
					},
					{
						num: 20, //原5
						title: "สภาพความเป็นอยู่",
						type: "Radio",
						ver: true,
						multiple: false,
						trigger: true,
						value: null,
						otherinput: true,
						list: ["อยู่คนเดียว", "อยู่กับสามีภรรยา และลูก", "อยู่กับพ่อแม่", "ไม่มีความเห็น"],
						key: "living_situation",
					},
				],
				irrigation_ditch: null,
				ischekout: true,
				is_showQCode: false,
				indexNumber: 4,
				formData: null,
				showMsg: false,
			};
		},
		mounted() {
			// eslint-disable-next-line
			if (localStorage.getItem("NPS_showQCode") != null) {
				this.is_showQCode = true;
			}
			this.init();
		},
		methods: {
			init() {
				if (localStorage.getItem("NationName") && localStorage.getItem("LoginInfo")) {
					this.formData = JSON.parse(localStorage.getItem("LoginInfo"));
					this.formData.Nation = localStorage.getItem("NationName");
					this.formdata[1].list = this.randomSort(this.formdata[1].list); //打乱数组排序
					this.formdata[4].list = this.randomSort(this.formdata[4].list); //打乱数组排序
					this.formdata[4].list.push("อื่นๆ");
					this.formdata[7].list = this.randomSort(this.formdata[7].list); //打乱数组排序
					this.formdata[7].list.push("อื่นๆ");
					this.formdata[10].list = this.randomSort(this.formdata[10].list); //打乱数组排序
					this.formdata[10].list.push("อื่นๆ");
					this.formdata[11].list = this.randomSort(this.formdata[11].list); //打乱数组排序
					this.formdata[11].list.push("อื่นๆ");
					this.$forceUpdate();
					// this.isSubForm(res => { //是否展示弹窗
					// 	this.showMsg = res;
					// })
				} else {
					this.$router.replace("/index");
				}
			},
			//是否提交过问卷
			isSubForm(callback) {
				this.axios
					.get(`/api/is_questionnaire_investigation/`, {
						params: {
							login_user: this.formData.User_email,
						},
					})
					.then((res) => {
						let resstr = res.data;
						if (parseInt(resstr.code) === 200) {
							callback(resstr.msg ? true : false);
						} else if (parseInt(resstr.code) === 40000) {} else {
							// eslint-disable-next-line
							Toast(resstr.msg);
						}
					})
					.catch((err) => {
						console.log(err);
					});
			},
			clearData() {
				localStorage.removeItem("NPS_showQCode");
				// window.location
				window.location.reload();
			},
			handCopy(text) {
				// text是复制文本
				// 创建input元素
				const el = document.createElement("input");
				// 给input元素赋值需要复制的文本
				el.setAttribute("value", text);
				// 将input元素插入页面
				document.body.appendChild(el);
				// 选中input元素的文本
				el.select();
				// 复制内容到剪贴板
				document.execCommand("copy");
				// 删除input元素
				document.body.removeChild(el);
				Toast("คัดลอกรหัสคูปอง");
			},
			submitbtns() {
				this.showsubmit = false;
				this.newcustomer = true;
			},
			settrigger(type = "0") {
				for (let i = 0; i < this.formdata.length; i++) {
					if (type == 0) {
						this.formdata[i].trigger = true;
						this.formdata[i].indexNumber = false;
						this.indexNumber = null;
					} else {
						let keystr = ["first_impression", "what_about", "score"];
						if (keystr.indexOf(this.formdata[i].key) !== -1) {
							if (this.formdata[i].indexNumber != undefined) {
								this.formdata[i].indexNumber = true;
								this.indexNumber = 4;
							}
						}
						// if (this.formdatafilter.indexOf(this.formdata[i].key) !== -1) {
						// 	this.formdata[i].trigger = false;
						// }
					}
				}
			},
			changeradio(item) {
				//console.log(item);
				// eslint-disable-next-line
				for (let i = 0; i < this.formdata.length; i++) {
					if (this.formdata[i].key === item.key) {
						if (!this.formdata[i].multiple) {
							if (this.formdata[i].value == "อื่นๆ") {
								let value = this.formdata[i].value;
								this.formdata[i].valueotherstr = this.formdata[i].valueother == null ? "" : value + "_+_" +
									this.formdata[i].valueother;
								this.formdata[i].valueotherstrdisabled = false;
							} else {
								this.formdata[i].valueotherstr = null;
								this.formdata[i].valueother = null;
								this.formdata[i].valueotherstrdisabled = true;
							}
						}
					}
				}
			},
			changecheckbox(item) {
				//console.log(item);
				let arr = [];
				for (let i = 0; i < this.formdata.length; i++) {
					if (this.formdata[i].key === item.key) {
						if (this.formdata[i].multiple) {
							let newitem = this.formdata[i].value;
							if (newitem.length != 0 && newitem.indexOf("อื่นๆ") != -1) {
								this.formdata[i].valueotherstr == null ? "" : newitem.valueother;
								this.formdata[i].valueotherstrdisabled = false;
							} else {
								this.formdata[i].valueotherstr = null;
								this.formdata[i].valueother = null;
								this.formdata[i].valueotherstrdisabled = true;
							}
							if (this.formdata[i].showDisabled) {
								this.formdata[i].disabledItem = item.value.includes(this.formdata[i].showDisabled);
							}
						}

						if (item.key === "think_brand") {
							this.formdata[4].value.forEach((item) => {
								if (item != "อื่นๆ" && item != this.formdata[4].showDisabled) {
									let currData = this.formdata[5].list.filter((it) => it.key == item);
									arr.push({
										key: item,
										val: currData.length ? currData[0].val : 0,
									});
								}
							});
							this.formdata[5].list = arr;
							this.formdata[5].hidden = !this.formdata[5].list.length || this.formdata[4].value.includes(this
								.formdata[4].showDisabled);
						}
					}
					this.setvaluenum();

					// if (this.formdata[i].key == "think_brand") {
					//     // eslint-disable-next-line
					//     // console.log(this.formdata[i].value);
					//   if (item.value.length && item.value.indexOf('Simplus')!=-1) {
					//     this.ischekout = 'think_brand'
					//     this.settrigger(0);
					//   } else {
					//     this.ischekout = false
					//     this.settrigger(1);
					//   }
					// }
				}
			},
			setvaluenum() {
				let filterArrs = this.formdata.filter((it) => !it.hidden);
				for (let i = 0; i < this.formdata.length; i++) {
					let findIndex = filterArrs.findIndex((it) => it.key == this.formdata[i].key);
					this.formdata[i].num = findIndex + 1;
				}
			},
			setvalueother(key) {
				for (let i = 0; i < this.formdata.length; i++) {
					if (this.formdata[i].key === key) {
						if (this.formdata[i].multiple) {
							if (this.formdata[i].value && this.formdata[i].value.indexOf("อื่นๆ") != -1) {
								this.formdata[i].valueotherstr = this.formdata[i].valueother == null ? "" : this.formdata[
									i].valueother;
							}
						} else {
							if (this.formdata[i].value == "อื่นๆ") {
								let value = this.formdata[i].value;
								this.formdata[i].valueotherstr = this.formdata[i].valueother == null ? "" : value + "_+_" +
									this.formdata[i].valueother;
							}
						}
					}
				}
			},
			setvalue(key, type = "0") {
				let valuelist = [];
				for (let i = 0; i < this.formdata.length; i++) {
					if (this.formdata[i].key === key) {
						this.formdata[i].list.filter((res) => {
							valuelist.push(res.value);
						});
						if (type == 0) {
							this.formdata[i].value = valuelist.filter((res) => res != "").join("&&");
						} else {
							if (valuelist.length === 2) {
								this.formdata[i].value = valuelist.filter((res) => res != "");
							}
						}
					}
				}
			},
			validatorther(item, rule) {
				let isver = true;
				if (item == null) {
					isver = false;
				} else {
					if (typeof item == "object") {
						let newitem = this.formdata
							.map((res) => {
								if (res.key == rule.name) {
									return res;
								}
							})
							.filter((res) => res != undefined);
						if (newitem[0].value.length === 0) {
							isver = false;
						} else {
							if (newitem[0].value.indexOf("อื่นๆ") != -1) {
								if (newitem[0].valueotherstr == null || newitem[0].valueotherstr == "") {
									isver = false;
								}
							}
						}
					}
					if (typeof item == "string") {
						if (item.indexOf("อื่นๆ") != -1) {
							this.formdata.map((res) => {
								if (res.key == rule.name) {
									if (res.valueotherstr == null || res.valueotherstr == "") {
										isver = false;
									} else {
										if (res.valueotherstr.split("_+_")[1] == "") {
											isver = false;
										}
									}
								}
							});
						}
					}
				}
				return isver;
			},
			validator(val) {
				let vernumer = false;
				let oldval = val;
				// const reg =	/^([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})$/;
				const reg =	/^([a-zA-Z0-9._-])+(([a-zA-Z0-9._-])|([._-][a-zA-Z0-9_])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})$/;
				// eslint-disable-next-line
				// const phonever = /^(\+?0?66\-?)?\d{10}$/;
				const phonever = /^[0-9]+.?[0-9]*/;
				if (oldval[0] != "") {
					if (!reg.test(oldval[0])) {
						// eslint-disable-next-line
						vernumer = false;
					} else {
						vernumer = true;
					}
				} else if (oldval[1] != null) {
					if (oldval[1].length < 5) {
						vernumer = false;
					}
					if (!phonever.test(oldval[1])) {
						vernumer = false;
					} else {
						vernumer = true;
					}
				}
				return vernumer;
			},
			retnumber() {
				return this.formdata.filter((res) => {
					return res.trigger || !res.title;
				});
			},
			mergeroown(obj) {
				let newObj = {};
				obj.forEach((item) => {
					newObj = {
						...newObj,
						...item,
					};
				});
				return newObj;
			},
			//打乱数组排序
			randomSort(arr) {
				for (let i = 0; i < arr.length; i++) {
					const randomIndex = Math.round(Math.random() * (arr.length - 1 - i)) + i;
					[arr[i], arr[randomIndex]] = [arr[randomIndex], arr[i]];
				}
				return arr;
			},
			userCenter() {},
			onSubmit() {
				this.$refs.MYSform.validate().then(
					(res) => {
						let data = this.formdata.map((res) => {
							let item = null;

							if (res.key == "email+phone_number") {
								let value = res.value;
								item = {
									[res.key.split("+")[0]]: value[0],
									[res.key.split("+")[1]]: value[1],
								};
							} else {
								let valuestr = res.value;
								if (res.valueotherstr) {
									valuestr += `&&${res.valueotherstr}`;
								}
								item = {
									[res.key]: valuestr,
								};
							}

							return {
								...item,
							};
						});

						let resultdata = this.mergeroown(data);

						if (this.formdata[5].list) {
							let arr = [];
							let array = this.formdata[5].list;
							for (let index = 0; index < array.length; index++) {
								if (!array[index].val) {
									this.score_error = true;
									this.$nextTick(() => {
										window.scrollTo(0, 1800);
									});
									break;
								}
								arr.push(`${array[index].key}&&${array[index].val}`);
							}
							if (this.score_error) {
								return;
							}
							resultdata.brand_score = arr;
						}
						console.log(resultdata);
						Object.keys(resultdata).map((res) => {
							if (typeof resultdata[res] == "object") {
								return (resultdata[res] = JSON.stringify(resultdata[res]));
							} else {
								return resultdata[res];
							}
						});
						if (this.isSubmit) {
							return;
						}
						this.isSubmit = true;
						this.axios
							.post(`/api/now_questionnaire_data/`, {
								questionnaire_details: JSON.stringify(resultdata),
								nation: "TH",
								type: 5,
								login_user: this.formData.User_email,
							})
							.then((resa) => {
								this.isSubmit = false;
								console.log(resa);
								if (resa.data.code === 200) {
									// eslint-disable-next-line
									this.showMsg = true;
									// this.is_showQCode = true;
								} else {
									Toast(resa.data.message);
								}
							})
							.catch((err) => {
								this.isSubmit = false;
								// eslint-disable-next-line
								console.log(err);
							});
					},
					(res) => {
						this.isSubmit = false;
						window.document.querySelector("#" + res[0].name).scrollIntoView(true);
						Toast("* จำเป็นต้อง");
					}
				);
			},
		},
	};
</script>

<style lang="less">
	.van-cell {
		flex-direction: column;
		font-size: 1rem;
		flex-wrap: nowrap;
		align-content: flex-start;
		border-radius: 0.5rem;
		margin-bottom: 0.6rem;
		font-family: SukhumvitSet !important;

		.van-cell__title {
			color: #333;
		}

		.van-cell__title::after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 0.5rem;
			border-top-left-radius: 0.5rem;
			border-top-right-radius: 0.5rem;
			background: #5263a2;
		}

		.van-field__label {
			margin-bottom: 0.5rem;
			width: 100%;
		}

		.van-radio {
			margin-bottom: 0.5rem;
		}

		.mb10 {
			margin-bottom: 0.5rem;
		}

		.describe {
			margin-top: 0.2rem;
			color: #666;
			font-size: 0.8rem;
			line-height: 1rem;
		}
	}

	.myc {
		background: #fff;
		padding: 0.8rem;
		border-radius: 0.5rem;
		margin-bottom: 0.8rem;
	}

	.btns {
		border-radius: 0.4rem;
		font-size: 0.8rem;
		height: 2.1rem;
		text-align: center;
		margin-bottom: 0.5rem;
	}

	.btnbox {
		margin-bottom: 2rem;

		.btnssubmit {
			background: #5263a2;
			color: #fff;
			border-color: #5263a2;
		}
	}

	.visit {
		background: #cbd1e2;
		width: 100vw;
		height: 100%;
		min-height: 100vh;

		.visitbox {
			padding: 0.8rem;

			.logo {
				img {
					height: 5rem;
					width: 100%;
					border-radius: 0.5rem;
				}
			}

			.coenter {
				margin-top: 0.5rem;
				font-size: 1rem;

				.colorred {
					color: red;
				}

				.tipred {
					color: red;
					margin-top: 0.4rem;
				}

				.ctitle {
					background: #fff;
					padding: 1.2rem 1rem;
					border-radius: 0.5rem;

					.complete {
						font-size: 1rem;
						width: 80%;
						margin: 0 auto;
						color: #666;
					}
				}

				.btnssubmit {
					background: #5263a2;
					color: #fff;
					border-color: #5263a2;
				}

				.colorbtn {
					color: #3884eb;
					font-size: 1rem;
				}

				.c-item {
					background: #fff;
					padding: 1.2rem 1rem;
					border-radius: 0.5rem;
					margin-bottom: 1rem;

					.ci-title {
						margin-bottom: 0.8rem;
					}

					.mb10 {
						margin-bottom: 1rem;
					}
				}

				.oneitem {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
				}

				.onetitle {
					background: #5263a2;
					color: #fff;
					padding: 1rem;
					border-top-left-radius: 0.5rem;
					border-top-right-radius: 0.5rem;
				}

				.bordercolor {
					position: relative;

					.ci-title {
						font-size: 1.3rem;
					}

					.linka {
						text-align: center;
					}
				}

				.bordercolor::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 0.5rem;
					border-top-left-radius: 0.5rem;
					border-top-right-radius: 0.5rem;
					background: #5263a2;
				}

				.btnbox {
					margin-top: 0.5rem;
				}
			}
		}

		.msg-box {
			width: 70vw;
			height: 260px;
			border-radius: 18px;
			padding: 24px;

			.msg-close {
				text-align: right;
			}

			.msg-logo {
				text-align: center;

				.logo {
					width: 60px;
					height: 70px;
				}
			}

			.msg-title {
				text-align: center;
				font-size: 16px;
				font-family: SukhumvitSet;
				font-weight: 600;
				color: #245bd9;
				padding: 12px;
				margin-bottom: 12px;
			}

			.msg-content {
				font-size: 14px;
				font-family: SukhumvitSet;
				font-weight: 500;
				color: #323233;
				margin-bottom: 12px;

				.link-text {
					margin-left: 12px;
					color: #245bd9;
					text-decoration: underline;
				}
			}
		}
	}
</style>
